import axios from 'axios';

// Get the API base URL from environment variables
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Create an Axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true // Required for cookies
});

// Function to set the Authorization header based on the stored token
export const setAuthToken = token => {
  if (token) {
    // Apply the token to every request if logged in
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    // Delete the auth header if logged out
    delete api.defaults.headers.common['Authorization'];
  }
};

// Track if we're currently refreshing the token
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// Add response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error is not 401 or it's a refresh token request that failed
    if (error.response?.status !== 401 || originalRequest.url === '/refresh') {
      return Promise.reject(error);
    }

    if (!isRefreshing) {
      isRefreshing = true;

      try {
        const { data } = await api.post('/refresh');
        const { access_token } = data;
        
        setAuthToken(access_token);
        localStorage.setItem('token', access_token);
        
        // Update the failed request with new token
        originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
        
        // Process all the requests in the queue
        processQueue(null, access_token);
        
        return api(originalRequest);
      } catch (err) {
        processQueue(err, null);
        // Clear auth state since refresh failed
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
        window.location.href = '/login';
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    }

    // Add failed request to the queue
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject });
    }).then(token => {
      originalRequest.headers['Authorization'] = `Bearer ${token}`;
      return api(originalRequest);
    }).catch(err => {
      return Promise.reject(err);
    });
  }
);

// Export the Axios instance for making API calls
export default api;
