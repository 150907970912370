import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import api from "../../utils/api";
import { useParams } from "react-router-dom";
import ConfirmationDialog from "../Utils/ConfirmationDialog";

const CustomerView = () => {
    let { customer_id } = useParams();

    const [customerDetail, setCustomerDetail] = useState({
        customer_name: "",
        created_at: ""
    });

    const [isEditing, setIsEditing] = useState(false);
    const [updatedCustomerName, setUpdatedCustomerName] = useState("");
    const [showSaveDialog, setShowSaveDialog] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    // Fetch customer details on mount
    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                const response = await api.get(`/customer/${customer_id}`);
                setCustomerDetail(response.data);
                setUpdatedCustomerName(response.data.customer_name);
            } catch (error) {
                console.error("Failed to fetch customer details", error);
            }
        };

        fetchCustomer();
    }, [customer_id]);

    // Toggle editing mode
    const handleEditToggle = () => {
        if (isEditing && hasChanges) {
            // If canceling with unsaved changes, reset to original value
            setUpdatedCustomerName(customerDetail.customer_name);
            setHasChanges(false);
        }
        setIsEditing(!isEditing);
    };

    // Handle name change
    const handleNameChange = (e) => {
        const newName = e.target.value;
        setUpdatedCustomerName(newName);
        setHasChanges(newName !== customerDetail.customer_name);
    };

    // Show save confirmation dialog
    const handleSaveClick = () => {
        if (hasChanges) {
            setShowSaveDialog(true);
        }
    };

    // Save updated customer details
    const handleConfirmSave = async () => {
        try {
            const updatedCustomer = {
                customer_name: updatedCustomerName
            };

            const response = await api.patch(`/customer/${customer_id}`, updatedCustomer);
            setCustomerDetail(response.data);
            setIsEditing(false);
            setHasChanges(false);
            setShowSaveDialog(false);
        } catch (error) {
            console.error("Failed to save customer details", error);
        }
    };

    const handleCancelSave = () => {
        setShowSaveDialog(false);
    };

    return (
        <Container>
            <h1 className="mt-4">Customer Details</h1>
            <Row className="py-2">
                <Col>
                    <strong>Name: </strong>
                    {isEditing ? (
                        <Form.Control
                            type="text"
                            value={updatedCustomerName}
                            onChange={handleNameChange}
                        />
                    ) : (
                        customerDetail.customer_name
                    )}
                </Col>
            </Row>
            <Row className="py-2">
                <Col>
                    <strong>Created At: </strong> {new Date(customerDetail.created_at).toLocaleString()}
                </Col>
            </Row>

            {/* Toggle between Edit and Save/Cancel buttons */}
            <Row className="mt-4">
                <Col>
                    {isEditing ? (
                        <>
                            <Button 
                                variant="success" 
                                onClick={handleSaveClick}
                                disabled={!hasChanges}
                            >
                                Save Changes
                            </Button>
                            <Button 
                                variant="secondary" 
                                className="ms-2" 
                                onClick={handleEditToggle}
                            >
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <Button variant="primary" onClick={handleEditToggle}>
                            Edit Customer
                        </Button>
                    )}
                </Col>
            </Row>

            <ConfirmationDialog
                show={showSaveDialog}
                onHide={handleCancelSave}
                onConfirm={handleConfirmSave}
                title="Save Changes"
                message="Are you sure you want to save the changes to this customer?"
                confirmLabel="Save"
                cancelLabel="Cancel"
            />
        </Container>
    );
};

export default CustomerView;
