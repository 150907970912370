import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import { AuthProvider, useAuth } from "./components/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import "./App.css";

import MinimalLayout from "./components/Layouts/MinimalLayout";
import MainLayout from "./components/Layouts/MainLayout";

import Login from "./components/Login/Login";
import ReportCreate from "./components/Reports/Create";
import ReportView from "./components/Reports/View";
import ReportList from "./components/Reports/List";
import CustomerCreate from "./components/Customers/Create";
import CustomerView from "./components/Customers/View";
import CustomerList from "./components/Customers/List";
import CsvDisplayTable from "./components/Reports/CsvDisplayTable";

import CreatePrompt from "./components/Prompts/Create";
import PromptList from "./components/Prompts/List";
import PromptEdit from "./components/Prompts/Edit";

import LeadList from "./components/Leads/List";
import LeadCreate from "./components/Leads/Create";
import LeadView from "./components/Leads/View";

import UserList from "./components/Users/List";
import UserView from "./components/Users/View";
import UserCreate from "./components/Users/Create";

import Plans from "./components/Checkout/Plans";

import Account from "./components/Settings/Account";

import Workflows from "./components/Workflow/index";
import CreateAgent from "./components/Workflow/Agents/Create";
import ListAgents from "./components/Workflow/Agents/List";
import ListRuns from "./components/Workflow/Runs/List";
import ViewAgent from "./components/Workflow/Agents/View";
import ViewRun from "./components/Workflow/Runs/View";
import ListSequences from "./components/Workflow/Sequences/List";
import ViewSequence from "./components/Workflow/Sequences/View";
import CreateSequence from "./components/Workflow/Sequences/Create";

import UploadFile from "./components/Workflow/Files/Upload";

import CustomerReports from "./components/Reports/CustomerReports";

import RunsList from "./components/Reports/View/RunsList";

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/plans" element={<Plans />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <ReportCreate />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/report/create"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <ReportCreate />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/report/view/:id"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <ReportView />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/report/list"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <ReportList />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/users/list"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <UserList />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/users/view/:id"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <UserView />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/users/create"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <UserCreate />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/customer/list"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <CustomerList />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/customer/create"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <CustomerCreate />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/customer/view/:customer_id"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <CustomerView />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/report/preview-csv"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <CsvDisplayTable file_key="13/cleaned_20240320220606.csv" />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/prompts/create"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <CreatePrompt />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/prompts/list"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <PromptList />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/prompts/edit/:id"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <PromptEdit />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/leads/list"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <LeadList />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/leads/view/:checksum"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <LeadView />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/leads/create"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <LeadCreate />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          {/* WORKFLOWS */}

          <Route
            path="/workflows/"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Workflows />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/workflows/sequences/list"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <ListSequences />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/workflows/sequences/create"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <CreateSequence />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/workflows/sequences/view/:sequenceId"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <ViewSequence />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/workflows/agents/list"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <ListAgents />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/workflows/runs/list"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <ListRuns />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/workflows/runs/view/:runId"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <ViewRun />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/workflows/agents/view/:agentId"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <ViewAgent />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/workflows/agents/create"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <CreateAgent />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/workflows/files/upload"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <UploadFile />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/sequence-runs"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <RunsList />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          {/* Define more routes as needed */}
          <Route
            path="/settings/account"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Account />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/customer/:id/projects"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <CustomerReports />
                </MainLayout>
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

// Redirects logged in users from Login page
const LoginRedirect = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  return isAuthenticated ? <Navigate to={location} replace /> : <Login />;
};

// Wraps components that require authentication
/* const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  console.log("isAuthenticated", isAuthenticated);
  //return isAuthenticated ? children : <Navigate to="/login" replace />;
  return children;
} */ export default App;
