import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Dropdown, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UserFriendlyDate from "../Utils/UserFriendlyDate";
import ConfirmationDialog from "../Utils/ConfirmationDialog";
import api from "../../utils/api";
import "./List.css";

const List = () => {
  const navigate = useNavigate();
  const [listCustomers, setListCustomers] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);

  const fetchCustomers = async () => {
    try {
      const response = await api.get("/customer/all");
      setListCustomers(response.data);
    } catch (error) {
      console.error("Failed to fetch customers", error);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleDeleteClick = (customer) => {
    setCustomerToDelete(customer);
    setShowDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/customer/${customerToDelete.id}`);
      setShowDeleteDialog(false);
      setCustomerToDelete(null);
      // Refresh the page using React Router navigation
      navigate(0);
    } catch (error) {
      console.error("Failed to delete customer", error);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setCustomerToDelete(null);
  };

  return (
    <Container>
      <h1 className="mt-4">
        All Customers{" "}
        <Button href="/customer/create" variant="outline-primary">
          Add
        </Button>
      </h1>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>id</th>
            <th>name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {[...listCustomers].sort((a, b) => {
            // Convert IDs to numbers for proper numerical sorting
            const idA = parseInt(a.id);
            const idB = parseInt(b.id);
            return idA - idB;
          }).map((customer) => (
            <tr key={customer.id}>
              <td>{customer.id}</td>
              <td>{customer.customer_name}</td>
              <td width="120px">
                <Dropdown>
                  <Dropdown.Toggle 
                    variant="success" 
                    id="dropdown-basic"
                    className="custom-dropdown-toggle"
                  >
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="custom-dropdown-menu">
                    <Dropdown.Item 
                      href={`/customer/view/${customer.id}`}
                      className="custom-dropdown-item"
                    >
                      View
                    </Dropdown.Item>
                    <Dropdown.Item 
                      href={`/customer/${customer.id}/projects`}
                      className="custom-dropdown-item"
                    >
                      Projects
                    </Dropdown.Item>
                    <Dropdown.Item 
                      as="button" 
                      onClick={() => handleDeleteClick(customer)}
                      className="custom-dropdown-item"
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <ConfirmationDialog
        show={showDeleteDialog}
        onHide={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title="Delete Customer"
        message={`Are you sure you want to delete customer "${customerToDelete?.customer_name}"? This action cannot be undone.`}
        confirmLabel="Delete"
        cancelLabel="Cancel"
      />
    </Container>
  );
};

export default List;