import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  InputGroup,
  Dropdown,
  Button,
} from "react-bootstrap";
import api from "../../../utils/agentApi";
import api2 from "../../../utils/api";

function UploadFile() {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState("");

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await api2.get('/report/all', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setProjects(response.data);
        console.log(response.data);

        // Get stored project, file, and websiteUrl if they exist
        const storedProject = localStorage.getItem('selectedProject');
        const storedFile = localStorage.getItem('selectedFile');
        const storedWebsiteUrl = localStorage.getItem('websiteUrl');

        if (storedWebsiteUrl) {
          setWebsiteUrl(storedWebsiteUrl);
        }

        if (storedProject) {
          setSelectedProject(storedProject);
          // Fetch files for the stored project
          const filesResponse = await api2.get(`/report/files/${storedProject}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          setFiles(filesResponse.data);
          
          if (storedFile) {
            setSelectedFile(storedFile);
          }
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };
    fetchProjects();
  }, []);

  const handleProjectChange = async (e) => {
    const projectId = e.target.value;
    setSelectedProject(projectId);
    setSelectedFile(""); // Reset selected file when project changes
    
    localStorage.setItem('selectedProject', projectId);
    localStorage.removeItem('selectedFile'); // Clear stored file when project changes
    
    if (projectId) {
      try {
        const token = localStorage.getItem('token');
        const response = await api2.get(`/report/files/${projectId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setFiles(response.data);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    } else {
      setFiles([]);
    }
  };

  const handleFileChange = (e) => {
    const fileValue = e.target.value;
    setSelectedFile(fileValue);
    localStorage.setItem('selectedFile', fileValue);
  };

  const handleWebsiteUrlChange = (e) => {
    const url = e.target.value;
    setWebsiteUrl(url);
    localStorage.setItem('websiteUrl', url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      alert("File and Website URL selected successfully");
    }
    catch (error) {
      console.error(error);
      alert("Error selecting file and website URL");
    }
    setIsLoading(false);
  };

  return (
    <Container>
      <h1>Add a New File</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Select Project</Form.Label>
          <Form.Select 
            value={selectedProject}
            onChange={handleProjectChange}
            required
          >
            <option value="">Choose a project...</option>
            {projects
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Select File</Form.Label>
          <Form.Select 
            value={selectedFile}
            onChange={handleFileChange}
            required
            disabled={!selectedProject}
          >
            <option value="">Choose a file...</option>
            {files
              .filter(file => file.state === "merged")
              .sort((a, b) => a.merged_filename_location.localeCompare(b.merged_filename_location))
              .map((file) => {
                const project = projects.find(p => p.id === parseInt(selectedProject));
                console.log(project);
                const displayName = project ? 
                  `${project.customer.customer_name}/${file.merged_filename_location}` : 
                  file.merged_filename_location;
                return (
                  <option key={file.id} value={file.merged_filename_location}>
                    {displayName}
                  </option>
                );
            })}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Website URL</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Enter website URL"
            value={websiteUrl}
            onChange={handleWebsiteUrlChange}
            required
          />
        </Form.Group>

        <Button 
          variant="primary" 
          type="submit" 
          disabled={isLoading || !selectedProject || !selectedFile || !websiteUrl}
        >
          {isLoading ? "Loading..." : "Submit"}
        </Button>
      </Form>
    </Container>
  );
}

export default UploadFile;
