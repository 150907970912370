import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Dropdown, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UserFriendlyDate from '../../Utils/UserFriendlyDate';
import ConfirmationDialog from "../../Utils/ConfirmationDialog";
import "../../../App.css"
import api from "../../../utils/agentApi";

function ListAgents() {
  const navigate = useNavigate();
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);

  useEffect(() => {
    const fetchLeads = async () => {
      setIsLoading(true);
      const response = await api.get("/get_agents");
      const sortedAgents = response.data.data.sort((a, b) => {
        const numA = parseInt(a.name) || a.name;
        const numB = parseInt(b.name) || b.name;
        return numA - numB;
      });
      setAgents(sortedAgents);
      setIsLoading(false);
    };
    fetchLeads();
  }, []);

  const handleDeleteClick = (agent) => {
    setAgentToDelete(agent);
    setShowDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    try {
      let agentData = {
        "agent_id": agentToDelete.id
      };

      const code_exec = await api.post(`/get_code_executions_by_agent`, agentData);
      const code_exec_result = code_exec.data.data;

      if (code_exec_result.length > 0) {
        for (let i = 0; i < code_exec_result.length; i++) {
          let code_exec_data = {
            "exec_id": code_exec_result[i].id
          };
          await api.post(`/remove_code_execution`, code_exec_data);
        }
      }

      await api.post(`/remove_agent`, agentData);
      setShowDeleteDialog(false);
      setAgentToDelete(null);
      // Navigate to refresh the page
      navigate(0);
    } catch (error) {
      console.error("Failed to delete agent", error);
    }
    setIsLoading(false);
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setAgentToDelete(null);
  };

  return (
    <Container>
      {isLoading && <p>Loading agents...</p>}
      {agents.length === 0 && !isLoading && <p>No Agents found.</p>}
      <h1>Agents{" "}
        <Button href="/workflows/agents/create" variant="outline-primary">
          New Agent
        </Button></h1>
      <Table striped bordered hover>
        {/*            {
            "id": "d3ccc896-e49b-4c2d-9e5d-419f76c34c6d",
            "created_at": "2024-05-28T16:09:26.617987+00:00",
            "name": "Customer Count",
            "model": "gpt-4o",
            "frequency_penalty": 0,
            "max_tokens": null,
            "presence_penalty": 0,
            "response_format": "text",
            "stream": false,
            "temperature": 0,
            "description": "Customer Count",
            "system_prompt": "",
            "custom_code": "const numberOfRowsExcludingHeader = inputData.length; result = numberOfRowsExcludingHeader;",
            "custom_input": {
                "filePath": "master.xlsx"
            }
        }, */}

        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Type</th>
            <th>Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {agents.map((agent) => (
            <tr key={agent.id}>
              <td>{agent.name}</td>
              <td>{agent.description}</td>
              <td>
                {agent.custom_code ? (
                  "Calculation"
                ) : (
                  "AI"
                )}
              </td>
              <td>
                <UserFriendlyDate timestamp={agent.created_at} /></td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic" className="custom-dropdown-toggle">
                    Actions
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="custom-dropdown-menu">
                    <Dropdown.Item href={`/workflows/agents/view/${agent.id}`} className="custom-dropdown-item">
                      View
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => handleDeleteClick(agent)} className="custom-dropdown-item">
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <ConfirmationDialog
        show={showDeleteDialog}
        onHide={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title="Delete Agent"
        message={`Are you sure you want to delete agent "${agentToDelete?.name}"? This action cannot be undone.`}
        confirmLabel="Delete"
        cancelLabel="Cancel"
      />
    </Container>
  );
}

export default ListAgents;
