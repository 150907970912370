import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import api from "../../../utils/agentApi";
import baseAPi from "../../../utils/api";

function ViewSequence() {
  let { sequenceId } = useParams();

  const [sequence, setSequence] = useState({});
  const [sequenceAgents, setSequenceAgents] = useState([]);
  const [agents, setAgents] = useState([]);
  const [files, setFiles] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedFile, setSelectedFile] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isRunLoading, setIsRunLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [runResult, setRunResult] = useState(null);
  const [runData, setRunData] = useState({});
  const [editData, setEditData] = useState({});

  const [selectedAgentId, setSelectedAgentId] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [runSaved, setRunSaved] = useState(false);

  useEffect(() => {
    const fetchDemo = async () => {
      try {
        setIsLoading(true);
        const response = await api.post(`/get_sequence`, { seq_id: sequenceId });
        setSequence(response.data.data);
        setEditData(response.data.data); // Initialize edit data with sequence data

        const seqAgents = await api.get(`/get_sequence_agents`);
        const seqAgentsData = seqAgents.data.data;
        setSequenceAgents(
          seqAgentsData.filter((agent) => agent.sequence_id === sequenceId)
        );

        const agentsResponse = await api.get("/get_agents");
        setAgents(agentsResponse.data.data);

        console.log("Current Axios headers:", api.defaults.headers.common);
        const filesResponse = await baseAPi.get("/file/all_files");
        setFiles(filesResponse.data);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setIsLoading(false);
      }
    };
    fetchDemo();
  }, [sequenceId]);

  useEffect(() => {
    const fetchProjectsAndInitialize = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await baseAPi.get('/report/all', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setProjects(response.data);

        // Get stored project and file if they exist
        const storedProject = localStorage.getItem('selectedProject');
        const storedFile = localStorage.getItem('selectedFile');

        if (storedProject) {
          setSelectedProject(storedProject);
          // Fetch files for the stored project
          const filesResponse = await baseAPi.get(`/report/files/${storedProject}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          setFiles(filesResponse.data);

          if (storedFile) {
            setSelectedFile(storedFile);
          }
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };
    fetchProjectsAndInitialize();
  }, []);

  // Handle input changes for agent parameters
  const handleChange = (e, agentId, field) => {
    const { value } = e.target;
    setRunData((prevData) => ({
      ...prevData,
      [agentId]: {
        ...prevData[agentId],
        [field]: value,
      },
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  };

  const handleAgentRoleChange = (agentId, role) => {
    setSequenceAgents((prevAgents) =>
      prevAgents.map((agent) =>
        agent.agent_id === agentId ? { ...agent, role } : agent
      )
    );
  };

  const handleAgentChange = async (agentId, sequenceAgentId) => {
    console.log(agentId, sequenceAgentId);
    const selectedAgent = agents.find((agent) => agent.id === agentId);
    const sequenceAgent = sequenceAgents.find(
      (agent) => agent.id === sequenceAgentId
    );

    const updatedAgent = {
      seq_agent_id: sequenceAgent.id, // Existing agent ID
      sequence_id: sequenceId, // Use the current sequence ID
      agent_id: selectedAgent.id, // New agent ID selected
      role: sequenceAgent.role, // Keep the existing role
    };

    try {
      // Call the API to update the agent in the sequence
      await api.post("/new_or_update_sequence_agent", updatedAgent);

      // Update the state with the new agent data
      setSequenceAgents((prevAgents) =>
        prevAgents.map((agent) =>
          agent.id === sequenceAgentId
            ? { ...agent, agent_id: selectedAgent.id }
            : agent
        )
      );
    } catch (error) {
      console.error("Failed to update agent", error);
    }
  };


  const toggleEdit = async () => {
    if (isEditing) {
      try {
        // Prepare sequence data for update, including new agents
        const updatedSequence = {
          seq_id: sequenceId,
          name: editData.name,
          description: editData.description,
          agents: sequenceAgents.map((agent) => ({
            agent_id: agent.agent_id,
            role: agent.role,
            // Only check startsWith if id exists
            id: agent.id && agent.id.startsWith("new_") ? null : agent.id,
            sequence_id: sequenceId, // Ensure the new agents have the correct sequence_id
          })),
        };

        // Send the updated sequence including agents to the backend
        await api.post(`/new_or_update_sequence`, updatedSequence);

        // Update the local state with the saved data
        setSequence(editData);
      } catch (error) {
        console.error("Error updating sequence:", error);
        return; // Exit without toggling if there's an error
      }
    }
    setIsEditing(!isEditing); // Toggle between edit and view mode
  };

 
 
  async function RunSequence() {
    setIsRunLoading(true);
    try {
      const sequenceData = {
        sequence_id: sequenceId,
        user_id: "20173961-a991-4487-953f-4e47f887ef0d",
        merged_file_key: selectedFile,
        agent_payloads: sequenceAgents.map(agent => {
          const agentData = runData[agent.agent_id] || {};
          const payload = {
            agent_id: agent.agent_id,
            user_prompt: agentData.user_prompt || ""
          };
          
          if (agentData.website_url) {
            payload.website_url = agentData.website_url;
          }
          
          return payload;
        })
      };

      const response = await api.post("/run_sequence", sequenceData);
      setRunResult(response.data.final_result);
      window.scrollTo({ top: 1000, behavior: "smooth" });
      setIsRunLoading(false);
    } catch (error) {
      console.error(error);
      setIsRunLoading(false);
    }
  }

  // Handle the change in the dropdown to select an agent
  const handleSelectAgent = (e) => {
    setSelectedAgentId(e.target.value); // Update the selected agent ID
  };

  // Add a new agent to the sequence with the selected ID
  const handleAddAgent = async () => {
    if (!selectedAgentId) {
      alert("Please select an agent to add."); // Ensure the user selects an agent
      return;
    }

    // Check if the agent is already in the sequence
    if (sequenceAgents.some(agent => agent.agent_id === selectedAgentId)) {
      alert("This agent is already part of the sequence.");
      return;
    }

    const newAgent = {
      sequence_id: sequenceId, // Ensure the agent is linked to the current sequence
      agent_id: selectedAgentId, // Use the selected agent ID
      role: 'source', // Default role for a new agent
    };

    try {
      // Call the API to add the new agent to the sequence
      const response = await api.post("/new_or_update_sequence_agent", newAgent);
      const savedAgent = response.data.data; // Get the saved agent data

      // Add the new agent to the sequenceAgents state using the response from the backend
      setSequenceAgents((prevAgents) => [...prevAgents, savedAgent]);

      // Reset the dropdown selection after adding the agent
      setSelectedAgentId('');
    } catch (error) {
      console.error("Failed to add agent", error);
    }
  };

  // Remove an agent by index
  const handleRemoveAgent = async (index) => {
    const agentToRemove = sequenceAgents[index]; // Get the agent to remove

    try {
      // Make an API call to remove the agent
      await api.post("/remove_sequence_agent", {
        seq_agent_id: agentToRemove.id, // Pass the ID of the agent to be removed
      });

      // Update the state to remove the agent from the UI
      setSequenceAgents((prevAgents) =>
        prevAgents.filter((_, agentIndex) => agentIndex !== index)
      );
    } catch (error) {
      console.error("Failed to remove agent", error);
    }
  };

  const handleProjectChange = async (e) => {
    const projectId = e.target.value;
    setSelectedProject(projectId);
    setSelectedFile(""); // Reset selected file when project changes

    localStorage.setItem('selectedProject', projectId);
    localStorage.removeItem('selectedFile'); // Clear stored file when project changes

    if (projectId) {
      try {
        const token = localStorage.getItem('token');
        const response = await baseAPi.get(`/report/files/${projectId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setFiles(response.data);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    } else {
      setFiles([]); // Clear files if no project selected
    }
  };

  const handleFileChange = (e) => {
    const fileValue = e.target.value;
    setSelectedFile(fileValue);
    localStorage.setItem('selectedFile', fileValue);
  };

  const handleSaveRun = async () => {
    setIsSaving(true);
    try {
      const payload = {
        merged_file_key: selectedFile,
        result: runResult,
        sequence_id: sequenceId
      };
      
      await api.post('/save_sequence_run', payload);
      setRunSaved(true); // Set to saved after successful API call
      alert('Run saved successfully!');
    } catch (error) {
      console.error('Error saving run:', error);
      alert('Failed to save run');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Container>
      {isLoading && <p>Loading sequence...</p>}
      {!isLoading && (
        <>
          <h1>{sequence.name}</h1>
          <Row>
            <Col xs={2}>
              <strong>Name: </strong>
            </Col>
            <Col>
              {isEditing ? (
                <Form.Control
                  type="text"
                  name="name"
                  value={editData.name}
                  onChange={handleInputChange}
                />
              ) : (
                sequence.name
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>Description: </strong>
            </Col>
            <Col>
              {isEditing ? (
                <Form.Control
                  type="text"
                  name="description"
                  value={editData.description}
                  onChange={handleInputChange}
                />
              ) : (
                sequence.description
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>Created: </strong>
            </Col>
            <Col>{sequence.created_at}</Col>
          </Row>

          {runResult && (
            <Row className="mt-3">
              <Col xs={2}>
                <strong>Result: </strong>
              </Col>
              <Col>
                <ReactMarkdown>{runResult}</ReactMarkdown>
                <Button 
                  variant="success" 
                  onClick={handleSaveRun}
                  disabled={isSaving || runSaved}
                  className="mt-2"
                >
                  {isSaving ? 'Saving...' : runSaved ? 'Saved' : 'Save Run'}
                </Button>
              </Col>
            </Row>
          )}

          <div className="mt-4">
            <h3>Upload File</h3>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Select Project</Form.Label>
                <Form.Select 
                  value={selectedProject}
                  onChange={handleProjectChange}
                  required
                >
                  <option value="">Choose a project...</option>
                  {projects
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((project) => (
                      <option key={project.id} value={project.id}>
                        {project.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Select File</Form.Label>
                <Form.Select 
                  value={selectedFile}
                  onChange={handleFileChange}
                  required
                  disabled={!selectedProject}
                >
                  <option value="">Choose a file...</option>
                  {files
                    .filter(file => file.state === "merged")
                    .sort((a, b) => a.merged_filename_location.localeCompare(b.merged_filename_location))
                    .map((file) => {
                      const project = projects.find(p => p.id === parseInt(selectedProject));
                      const displayName = project ? 
                        `${project.customer.customer_name}/${file.merged_filename_location}` : 
                        file.merged_filename_location;
                      return (
                        <option key={file.id} value={file.merged_filename_location}>
                          {displayName}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
            </Form>
          </div>

          <div className="mt-4">
            <h3 className="mt-4">Run Agent</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Source Agents</th>
                  <th>Parameters</th>
                </tr>
              </thead>
              <tbody>
                {sequenceAgents.map((agent, index) => (
                  <tr key={agent.id || `new-agent-${index}`}>
                    <td>
                      {isEditing ? (
                        <Form.Control
                          as="select"
                          value={agent.agent_id}
                          onChange={(e) => handleAgentChange(e.target.value, agent.id)}
                        >
                          {agents.map((a) => (
                            <option key={a.id} value={a.id}>
                              {a.name}
                            </option>
                          ))}
                        </Form.Control>
                      ) : (
                        <a href={`/workflows/agents/view/${agent.agent_id}`}>
                          {agents.find((a) => a.id === agent.agent_id)?.name}
                        </a>
                      )}
                    </td>
                    <td>
                      {isEditing ? (
                        <Form.Control
                          as="select"
                          value={agent.role}
                          onChange={(e) => handleAgentRoleChange(agent.id, e.target.value)}
                        >
                          <option value="source">Source</option>
                          <option value="target">Target</option>
                        </Form.Control>
                      ) : (
                        agent.role
                      )}
                    </td>
                    {isEditing && (
                      <td>
                        <Button variant="danger" onClick={() => handleRemoveAgent(index)}>
                          Remove
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>

            {isEditing && (
              <div>
                {/* Dropdown to select an agent to add */}
                <Form.Group>
                  <Form.Label>Select Agent to Add:</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedAgentId}
                    onChange={handleSelectAgent}
                  >
                    <option value="">Select an agent</option>
                    {agents.map((a) => (
                      <option key={a.id} value={a.id}>
                        {a.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {/* Button to add the selected agent */}
                <Button variant="success" onClick={handleAddAgent} className="mt-2">
                  Add Source Agent
                </Button>
              </div>
            )}

            <Button variant="warning" onClick={toggleEdit} style={{ marginBottom: "20px", marginTop: "20px" }}>
              {isEditing ? "Save" : "Edit"}
            </Button>
            &nbsp;

            {
              isEditing && <Button variant="none" onClick={toggleEdit}>
                Cancel
              </Button>
            }


            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Agent Name</th>
                  <th>Role</th>
                  {isEditing && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {sequenceAgents.map((agent, index) => (
                  <tr key={agent.id || `new-agent-${index}`}>
                    <td>
                      {agents.find((a) => a.id === agent.agent_id)?.name} (
                      {agent.role})
                    </td>
                    <td>
                      {/* User Prompt */}
                      <Form.Group className="mb-3">
                        <Form.Label>User Prompt: </Form.Label>
                        <Form.Control
                          type="text"
                          name="user_prompt"
                          value={runData[agent.agent_id]?.user_prompt || ""}
                          onChange={(e) => handleChange(e, agent.agent_id, "user_prompt")}
                        />
                      </Form.Group>

                      {/* Website URL */}
                      <Form.Group className="mb-3">
                        <Form.Label>Website URL: </Form.Label>
                        <Form.Control
                          type="text"
                          name="website_url"
                          value={runData[agent.agent_id]?.website_url || ""}
                          onChange={(e) => handleChange(e, agent.agent_id, "website_url")}
                        />
                      </Form.Group>

                      {/* File URL */}
                      <Form.Group className="mb-3">
                        <Form.Label>File URL: </Form.Label>
                        <Form.Control
                          as="select"
                          name="file_url"
                          value={runData[agent.agent_id]?.file_url || ""}
                          onChange={(e) => handleChange(e, agent.agent_id, "file_url")}
                        >
                          <option value="">Select a file</option>
                          {files.map((file) => (
                            <option key={file.id} value={file.file_url}>
                              {file.original_filename}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </td>
                  </tr>
                ))}
              </tbody>

            </Table>

            <Button
              variant="primary"
              disabled={isRunLoading}
              onClick={!isRunLoading ? RunSequence : null}
              className="my-4"
            >
              {isRunLoading ? "Loading…" : "Run sequence"}
            </Button>
          </div>
        </>
      )}
    </Container>
  );
}

export default ViewSequence;
