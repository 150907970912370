import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Dropdown, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import api from "../../utils/api";
import UserFriendlyDate from "../Utils/UserFriendlyDate";
import ConfirmationDialog from "../Utils/ConfirmationDialog";
import "../../App.css"

const List = () => {
  const [prompts, setPrompts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [promptToDelete, setPromptToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const fetchPrompts = async () => {
      setIsLoading(true);
      try {
        const response = await api.get("/prompts/all");
        if (isMounted) {
          setPrompts(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Failed to fetch prompts", error);
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    
    fetchPrompts();
    
    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, []);  // Remove sortConfig dependency to prevent infinite loops

  const sortItems = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    if (sortConfig !== null) {
      setPrompts(prevPrompts => 
        [...prevPrompts].sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        })
      );
    }
  }, [sortConfig]);

  // Handle delete button click
  const handleDeleteClick = (prompt) => {
    setPromptToDelete(prompt);
    setShowDeleteDialog(true);
  };

  // Confirm and delete the prompt
  const handleConfirmDelete = async () => {
    setIsLoading(true);
    try {
      // First hide the dialog
      setShowDeleteDialog(false);
      
      // Then make the API call
      await api.delete(`/prompts/${promptToDelete.id}`);
      
      // Update local state to remove the deleted item
      setPrompts(prevPrompts => prevPrompts.filter(p => p.id !== promptToDelete.id));
      
      // Show success message
      alert("Prompt deleted successfully");
      
      // Force a clean refresh of the page to ensure UI is responsive
      window.location.reload();
      
    } catch (error) {
      console.error("Failed to delete prompt", error);
      alert("Error deleting prompt: " + (error.response?.data?.detail || error.message));
      setIsLoading(false);
    }
  };

  return (
    <Container>
      {isLoading && <p>Loading prompts...</p>}
      {prompts.length === 0 && !isLoading && <p>No prompts found.</p>}
      <h1>
        Prompts
        <Button href="/prompts/create" variant="outline-primary">
          Add
        </Button>
      </h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => sortItems("name")}>
              Name
              {sortConfig &&
                sortConfig.key === "name" &&
                (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
            </th>
            <th onClick={() => sortItems("description")}>
              Description
              {sortConfig &&
                sortConfig.key === "description" &&
                (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
            </th>
            <th onClick={() => sortItems("prompt")}>
              Prompt
              {sortConfig &&
                sortConfig.key === "prompt" &&
                (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
            </th>
            <th onClick={() => sortItems("created_at")}>
              Created
              {sortConfig &&
                sortConfig.key === "created_at" &&
                (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
            </th>
            <th onClick={() => sortItems("updated_at")}>
              Updated
              {sortConfig &&
                sortConfig.key === "updated_at" &&
                (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {prompts.map((prompt) => (
            <tr key={prompt.id}>
              <td>
                {prompt.name}
              </td>
              <td>{prompt.description}</td>
              <td>
                <small>{prompt.prompt}</small>
              </td>
              <td>
                <UserFriendlyDate timestamp={prompt.created_at} />
              </td>
              <td>
                <UserFriendlyDate timestamp={prompt.updated_at} />
              </td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic" className="custom-dropdown-toggle">
                    Actions
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="custom-dropdown-menu" >
                    <Dropdown.Item href={`/prompts/edit/${prompt.id}`} className="custom-dropdown-item" >
                      View
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => handleDeleteClick(prompt)} className="custom-dropdown-item">
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Confirmation Dialog */}
      <ConfirmationDialog
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Delete"
        message={`Are you sure you want to delete the prompt "${promptToDelete?.name}"?`}
        confirmLabel="Delete"
        confirmVariant="danger"
      />
    </Container>
  );
};

export default List;
