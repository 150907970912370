import { react } from "react";
import { Container, Card, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Workflows() {

  const navigate = useNavigate();

  return (
    <Container>
      <h1>Workflows</h1>

      <Row>
        <Col>
          <Card style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title>Run Project</Card.Title>
              <Card.Text>
              Select customer file and input URL for agents to use.
              </Card.Text>
              <Button variant="primary" onClick={() => navigate('/workflows/files/upload')}>Run project</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title>Agents</Card.Title>
              <Card.Text>
                Create and manage agents to automate workflows.
              </Card.Text>
              <Button variant="primary" onClick={() => navigate('/workflows/agents/list')}>View agents</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title>Sequences</Card.Title>
              <Card.Text>
                Create sequences of agents to automate workflows.
              </Card.Text>
              <Button variant="primary" onClick={() => navigate('/workflows/sequences/list')}>View sequences</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title>Runs</Card.Title>
              <Card.Text>
                Create and manage Runs to run multiple sequences.
              </Card.Text>
              <Button variant="primary" onClick={() => navigate('/workflows/runs/list')}>View runs</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </Container>
  );
}

export default Workflows;
