import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../utils/api";
import ConfirmationDialog from "../Utils/ConfirmationDialog";

const Edit = () => {
    
    let { id } = useParams();
    const navigate = useNavigate();
    
    const [prompt, setPrompt] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showSaveDialog, setShowSaveDialog] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [originalPrompt, setOriginalPrompt] = useState({});

    useEffect(() => {
        const fetchPrompt = async () => {
            setIsLoading(true);
            const response = await api.get(`/prompts/${id}`);
            setPrompt(response.data);
            setOriginalPrompt(response.data);
            setIsLoading(false);
        };
        fetchPrompt();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPrompt((prevPrompt) => ({
            ...prevPrompt,
            [name]: value,
        }));
        setHasChanges(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        setShowSaveDialog(true);
    };

    const handleConfirmSave = async () => {
        setIsLoading(true);
        try {
            // First hide the dialog
            setShowSaveDialog(false);
            
            // Then make the API call
            await api.put(`/prompts/${id}`, prompt);
            
            // Update state
            setHasChanges(false);
            setOriginalPrompt({...prompt});
            
            // Show success message
            alert("Prompt updated successfully");
            
            // Navigate to the correct list page
            navigate("/prompts/list");
            
        } catch (error) {
            console.error("Failed to update prompt", error);
            alert("Error updating prompt: " + (error.response?.data?.detail || error.message));
            setShowSaveDialog(false);
            setIsLoading(false);
        }
    };

    return (
        <Container>
            {isLoading && <p>Loading prompt...</p>}
            {!isLoading && (
                <>
                    <h1>Edit Prompt</h1>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                                Name
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={prompt.name || ''}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                                Description
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    as="textarea"
                                    name="description"
                                    value={prompt.description || ''}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                                Prompt
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    as="textarea"
                                    name="prompt"
                                    value={prompt.prompt || ''}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Form.Group>
                        <Button type="submit" disabled={!hasChanges}>Update</Button>
                    </Form>

                    {/* Save Confirmation Dialog */}
                    <ConfirmationDialog
                        show={showSaveDialog}
                        onHide={() => setShowSaveDialog(false)}
                        onConfirm={handleConfirmSave}
                        title="Confirm Save"
                        message="Are you sure you want to save these changes?"
                        confirmLabel="Save"
                        confirmVariant="primary"
                    />
                </>
            )}
        </Container>
    );
};

export default Edit;