import React, { useState, useEffect } from "react";
import { Table, Dropdown, Spinner, Modal, Button } from "react-bootstrap";
import UserFriendlyDate from "../../Utils/UserFriendlyDate";
import DataSummary from "./DataSummary";
import qs from "qs";
import api from "../../../utils/api";
import "../../../App.css";
import { useNavigate } from 'react-router-dom';

const Files = ({ report_id, report_state, project_customer_name }) => {
  const [reportFiles, setReportFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const navigate = useNavigate();
  console.log(reportFiles)

  useEffect(() => {
    fetchFiles();
  }, [report_state]);

  const fetchFiles = async (status_filter) => {
    try {
      const response = await api.get(`/report/files/${report_id}`);
      setReportFiles(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Failed to fetch files", error);
    }
  };

  const downloadFile = async (filename) => {
    try {
      // Constructing the form data as x-www-form-urlencoded
      const data = qs.stringify({
        file_key: filename,
      });

      const config = {
        method: "post",
        url: "/report/download-file/",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          accept: "application/json",
        },
        data: data,
        responseType: "blob", // Indicates that the response should be treated as a Blob
      };

      const response = await api(config);

      // Handling the Blob response to trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "downloadedFile.csv"); // Set a default filename or derive from response headers
      document.body.appendChild(link);
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Failed to download file.");
    }
  };

  const handleClean = async (file_id) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file_id", file_id);

      const response = await api.post("/report/clean/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          //setUploadProgress(percentCompleted); // Update upload progress
        },
      });

      fetchFiles();
      setIsLoading(false);
    } catch (error) {
      console.error("Error cleaning file:", error);
      alert("Failed to clean file.");
      setIsLoading(false);
    }
  };

  // Function to handle the delete confirmation
  const handleDeleteConfirm = async () => {
    if (fileToDelete) {
      try {
        const response = await api.delete(`/file/${fileToDelete}`);
        console.log("File deleted", response);
        fetchFiles();
        handleClose(); // Close the modal after deletion
      } catch (error) {
        console.error("Failed to delete file", error);
        handleClose(); // Close the modal if there's an error
      }
    }
  };

  // Show the confirmation modal with the file_id to delete
  const handleShow = (file_id) => {
    setShow(true);
    setFileToDelete(file_id);
  };

  // Close the confirmation modal
  const handleClose = () => {
    setShow(false);
    setFileToDelete(null);
  };

  const handleMerge = async (file_id) => {
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("report_id", report_id);
      formData.append("file_id", file_id);

      const response = await api.post("/report/merge/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          //setUploadProgress(percentCompleted); // Update upload progress
        },
      });

      fetchFiles();
      setIsLoading(false);
    } catch (error) {
      console.error("Error Merging file:", error);
      alert("Failed to merge file.");
      setIsLoading(false);
    }
  };

  const previewFile = async (filename) => {
    console.log("Previewing file", filename);
    window.open(`/report/preview-csv?file_key=${filename}`);
  };

  const formatFilePath = (path) => {
    if (!path) return "-";
    return `${project_customer_name}/${path}`; // Show full path with project_customer_name prefix
  };

  const handleViewRuns = (fileKey) => {
    navigate(`/sequence-runs?merged_file_key=${fileKey}`);
  };

  return (
    <>
      <div>
        {isLoading && (
          <div className="text-center">
            <Spinner animation="grow" />
          </div>
        )}

        <h4 className="mt-4">CSV files</h4>

        <div className="table-responsive">
          <Table striped bordered hover size="sm" className="fixed-table">
            <thead>
              <tr>
                <th className="id-column">ID</th>
                <th className="original-file-column">Original File</th>
                <th className="cleaned-file-column">Cleaned File</th>
                <th className="merged-file-column">Merged File</th>
                <th className="status-column">Status</th>
                <th className="created-column">Created</th>
                <th className="summary-column">Summary</th>
                <th className="action-column">Action</th>
              </tr>
            </thead>
            <tbody>
              {reportFiles.map((file) => (
                <tr key={file.id}>
                  <td>{file.id}</td>
                  <td>
                    <a href="#" onClick={() => downloadFile(file.original_filename_location)}>
                      {formatFilePath(file.original_filename_location)}
                    </a>
                    <br />
                    <small>
                      [<a href="#" className="small" onClick={() => previewFile(file.original_filename_location)}>
                        Preview
                      </a>]
                    </small>
                  </td>
                  <td>
                    {file.cleaned_filename !== null && file.cleaned_filename !== "" ? (
                      <>
                        <a href="#" onClick={() => downloadFile(file.cleaned_filename_location)}>
                          {formatFilePath(file.cleaned_filename_location)}
                        </a>
                        <br />
                        <small>
                          [<a href="#" className="small" onClick={() => previewFile(file.cleaned_filename_location)}>
                            Preview
                          </a>]
                        </small>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {file.merged_filename_location ? (
                      <>
                        <a href="#" onClick={() => downloadFile(file.merged_filename_location)}>
                          {formatFilePath(file.merged_filename_location)}
                        </a>
                        <br />
                        <small>
                          [<a href="#" className="small" onClick={() => previewFile(file.merged_filename_location)}>
                            Preview
                          </a>]
                        </small>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>{file.state}</td>
                  <td>
                    <UserFriendlyDate timestamp={file.created_at} />
                  </td>
                  <td>
                    <DataSummary data={file.summary} />
                  </td>
                  <td>
                    {file.state === "merged" ? (
                      <Button 
                        variant="primary" 
                        size="sm"
                        onClick={() => handleViewRuns(file.merged_filename_location)}
                      >
                        Runs
                      </Button>
                    ) : (
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          Action
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {file.state === "uploaded" && (
                            <Dropdown.Item href="#" onClick={() => handleClean(file.id)}>
                              Clean
                            </Dropdown.Item>
                          )}
                          {file.state === "cleaned" && (
                            <Dropdown.Item href="#" onClick={() => handleMerge(file.id)}>
                              Merge
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item href="#" onClick={() => handleShow(file.id)}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      {/* Confirmation Modal */}
      {show && (
        <Modal show={show} onHide={handleClose} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="danger" onClick={handleDeleteConfirm}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Files;
