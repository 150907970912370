import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

/**
 * Reusable confirmation dialog component
 * @param {boolean} show - Whether to show the dialog
 * @param {function} onHide - Function to call when the dialog is closed
 * @param {function} onConfirm - Function to call when the user confirms
 * @param {string} title - Dialog title
 * @param {string} message - Dialog message
 * @param {string} confirmLabel - Label for the confirm button
 * @param {string} cancelLabel - Label for the cancel button
 * @param {string} confirmVariant - Bootstrap variant for the confirm button
 */
const ConfirmationDialog = ({ 
  show, 
  onHide, 
  onConfirm, 
  title = "Confirm Action", 
  message = "Are you sure you want to perform this action?",
  confirmLabel = "Confirm",
  cancelLabel = "Cancel",
  confirmVariant = "primary"
}) => {
  const [isConfirming, setIsConfirming] = useState(false);

  // Reset isConfirming when dialog is closed
  useEffect(() => {
    if (!show) {
      setIsConfirming(false);
    }
  }, [show]);

  const handleConfirm = async () => {
    try {
      setIsConfirming(true);
      await onConfirm();
    } catch (error) {
      console.error('Error during confirmation:', error);
    } finally {
      setIsConfirming(false);
      onHide();
    }
  };

  const handleHide = () => {
    if (!isConfirming) {
      setIsConfirming(false);
      onHide();
    }
  };

  return (
    <Modal 
      show={show} 
      onHide={handleHide} 
      centered
      backdrop={isConfirming ? 'static' : true}
      keyboard={!isConfirming}
    >
      <Modal.Header closeButton={!isConfirming}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button 
          variant="secondary" 
          onClick={handleHide}
          disabled={isConfirming}
        >
          {cancelLabel}
        </Button>
        <Button 
          variant={confirmVariant} 
          onClick={handleConfirm}
          disabled={isConfirming}
        >
          {isConfirming ? 'Processing...' : confirmLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationDialog; 