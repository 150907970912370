import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container, Table, Spinner, Button, Modal } from 'react-bootstrap';
import api from '../../../utils/agentApi';
import UserFriendlyDate from '../../Utils/UserFriendlyDate';

const RunsList = () => {
  const [searchParams] = useSearchParams();
  const fileKey = searchParams.get('merged_file_key');
  const [runs, setRuns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [runToDelete, setRunToDelete] = useState(null);

  // Fetch runs
  useEffect(() => {
    fetchRuns();
  }, [fileKey]);

  const fetchRuns = async () => {
    if (!fileKey) {
      setLoading(false);
      return;
    }
    
    try {
      const response = await api.get(`/file-sequence-runs?merged_file_key=${fileKey}`);
      setRuns(response.data?.data || []);
    } catch (error) {
      console.error('Error fetching runs:', error);
      alert('Failed to fetch runs');
      setRuns([]);
    } finally {
      setLoading(false);
    }
  };

  // Delete handlers
  const handleDeleteClick = (run) => {
    setRunToDelete(run);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await api.post('/remove-sequence-runs', { id: runToDelete.id });
      await fetchRuns(); // Refresh the list
      setShowDeleteModal(false);
      setRunToDelete(null);
    } catch (error) {
      console.error('Error deleting run:', error);
      alert('Failed to delete run');
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRunToDelete(null);
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" />
      </Container>
    );
  }

  return (
    <Container className="mt-4">
      <h2>Sequence Runs</h2>
      <h5 className="text-muted mb-4">File: {fileKey}</h5>
      
      {runs.length === 0 ? (
        <p>No runs found for this file.</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Result</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {runs.map((run) => (
              <tr key={run.id}>
                <td>{run.id}</td>
                <td>{run.result}</td>
                <td><UserFriendlyDate timestamp={run.created_at} /></td>
                <td>
                  <Button 
                    variant="danger" 
                    size="sm"
                    onClick={() => handleDeleteClick(run)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleDeleteCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this run? (ID: {runToDelete?.id})
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCancel}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default RunsList;