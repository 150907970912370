import React, { useState, useEffect } from "react";
import { Container, Table, Form, Button } from "react-bootstrap";
import api from "../../../utils/agentApi";

function CreateSequence() {
  const [sequenceName, setSequenceName] = useState("");
  const [sequenceDescription, setSequenceDescription] = useState("");
  const [sourceAgents, setSourceAgents] = useState({});
  const [targetAgent, setTargetAgent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [listAgents, setListAgents] = useState([]);

  const sourceAgentsArray = [
    { id: 1, name: "agent_1" },
    { id: 2, name: "agent_2" },
    { id: 3, name: "agent_3" },
    { id: 4, name: "agent_4" },
  ];

  useEffect(() => {
    const fetchLeads = async () => {
      setIsLoading(true);
      const response = await api.get("/get_agents");
      setListAgents(response.data.data);
      setIsLoading(false);
    };
    fetchLeads();
  }, []);

  const handleSourceAgentChange = (e, id) => {
    setSourceAgents({ ...sourceAgents, [id]: e.target.value });
  };

  const handleTargetAgentChange = (e) => {
    setTargetAgent(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const sequenceData = {
      name: sequenceName,
      description: sequenceDescription
    };

    try {
      const createSequence = await api.post('/new_or_update_sequence', sequenceData);
      const sequenceId = createSequence.data.data[0].id;
              
      // Create a loop to add each source agent to the sequence
      for (const key in sourceAgents) {
        const sourceAgentData = {
          sequence_id: sequenceId,
          agent_id: sourceAgents[key],
          role: "source"
        };
        await api.post('/new_or_update_sequence_agent', sourceAgentData);
      }

      // Add the target agent to the sequence
      const targetAgentData = {
        sequence_id: sequenceId,
        agent_id: targetAgent,
        role: "target"
      };
      await api.post('/new_or_update_sequence_agent', targetAgentData);

      alert("Sequence created successfully");
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <Container>
      <h1>Create a new Sequence</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Sequence Name</Form.Label>
          <Form.Control
            type="text"
            value={sequenceName}
            onChange={(e) => setSequenceName(e.target.value)}
            placeholder="Name"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Sequence Description</Form.Label>
          <Form.Control
            as="textarea"
            value={sequenceDescription}
            onChange={(e) => setSequenceDescription(e.target.value)}
            placeholder="Description"
            required
          />
        </Form.Group>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Source Agents</th>
            </tr>
          </thead>
          <tbody>
            {sourceAgentsArray.map((agent) => (
              <tr key={agent.id}>
                <td>{agent.id}</td>
                <td>
                  <Form.Control
                    as="select"
                    value={sourceAgents[agent.id] || ""}
                    onChange={(e) => handleSourceAgentChange(e, agent.id)}
                  >
                    <option value="">Select</option>
                    {[...listAgents].sort((a, b) => {
                      // Extract numbers from the start of the name (e.g., "5.3" from "5.3 Agent Name")
                      const numA = parseFloat(a.name.match(/^\d+\.?\d*/)?.[0] || 0);
                      const numB = parseFloat(b.name.match(/^\d+\.?\d*/)?.[0] || 0);
                      return numA - numB;
                    }).map((listAgent) => (
                      <option key={listAgent.id} value={listAgent.id}>
                        {listAgent.name}
                      </option>
                    ))}
                  </Form.Control>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Target Agent</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>
                <Form.Control
                  as="select"
                  value={targetAgent}
                  onChange={handleTargetAgentChange}
                >
                  <option value="">Select</option>
                  {listAgents.map((listAgent) => (
                    <option key={listAgent.id} value={listAgent.id}>
                      {listAgent.name}
                    </option>
                  ))}
                </Form.Control>
              </td>
            </tr>
          </tbody>
        </Table>

        <Button variant="primary" type="submit" disabled={isLoading}>
          {isLoading ? "Creating..." : "Create Sequence"}
        </Button>
      </Form>
    </Container>
  );
}

export default CreateSequence;
