import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { setAuthToken } from "../utils/api";
import { jwtDecode } from "jwt-decode";
import api from "../utils/api";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const REFRESH_INTERVAL = 4 * 60 * 1000; // Refresh token 4 minutes before expiry

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  const refreshToken = useCallback(async () => {
    try {
      const response = await api.post("/refresh");
      const { access_token, expires_in } = response.data;
      
      localStorage.setItem("token", access_token);
      setAuthToken(access_token);
      
      // Schedule next refresh
      setTimeout(() => refreshToken(), (expires_in - 240) * 1000); // Refresh 4 minutes before expiry
      
      return true;
    } catch (error) {
      console.error("Failed to refresh token:", error);
      logout();
      return false;
    }
  }, []);

  const checkTokenExpiration = useCallback(async (token) => {
    if (!token) return false;
    
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      
      if (decoded.exp - currentTime > 240) { // More than 4 minutes until expiry
        setTimeout(() => refreshToken(), (decoded.exp - currentTime - 240) * 1000);
        return true;
      } else if (decoded.exp > currentTime) { // Less than 4 minutes until expiry
        return await refreshToken();
      }
      
      return false;
    } catch {
      return false;
    }
  }, [refreshToken]);

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem("token");
      const isValid = await checkTokenExpiration(token);
      
      if (isValid) {
        setIsAuthenticated(true);
        try {
          const response = await api.get("/users/me");
          setUser(response.data);
        } catch (error) {
          console.error("Failed to fetch user data:", error);
          logout();
        }
      } else {
        logout();
      }
      
      setIsLoading(false);
    };

    initializeAuth();
  }, [checkTokenExpiration]);

  const login = async (token) => {
    localStorage.setItem("token", token);
    setAuthToken(token);
    setIsAuthenticated(true);
    
    try {
      const response = await api.get("/users/me");
      setUser(response.data);
      
      const decoded = jwtDecode(token);
      const timeUntilRefresh = (decoded.exp - (Date.now() / 1000) - 240) * 1000;
      setTimeout(() => refreshToken(), timeUntilRefresh);
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      logout();
    }
  };

  const logout = async () => {
    try {
      await api.post("/logout");
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      localStorage.removeItem("token");
      localStorage.removeItem("currentUser");
      setAuthToken(null);
      setIsAuthenticated(false);
      setUser(null);
    }
  };

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      isLoading, 
      login, 
      logout,
      user 
    }}>
      {children}
    </AuthContext.Provider>
  );
};
