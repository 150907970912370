import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Table, Dropdown } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import UserFriendlyDate from "../Utils/UserFriendlyDate";
import ConfirmationDialog from "../Utils/ConfirmationDialog";
import api from "../../utils/api";

const CustomerReports = () => {
  const navigate = useNavigate();
  const [listReports, setListReports] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [customer, setCustomer] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await api.get(`/customer/${id}/reports`);
        const sortedReports = response.data.sort((a, b) => 
          a.name.localeCompare(b.name)
        );
        setListReports(sortedReports);
      } catch (error) {
        console.error("Failed to fetch reports", error);
      }
    };
    
    const fetchCustomer = async () => {
      try {
        const response = await api.get(`/customer/${id}`);
        setCustomer(response.data);
        console.log("customer", response.data);
      } catch (error) {
        console.error("Failed to fetch customer details:", error);
      }
    };

    fetchReports();
    fetchCustomer();
  }, [id]);

  const handleDeleteClick = (report) => {
    setReportToDelete(report);
    setShowDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/report/${reportToDelete.id}`);
      setShowDeleteDialog(false);
      setReportToDelete(null);
      // Refresh the page using React Router navigation
      navigate(0);
    } catch (error) {
      console.error("Failed to delete report", error);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setReportToDelete(null);
  };

  return (
    <Container>
      <h1 className="mt-4">
        Projects for {customer?.customer_name}{" "}
        <Button href={`/report/create?customer=${id}`} variant="outline-primary">
          Add
        </Button>
      </h1>

      {listReports.length === 0 ? (
        <div className="text-center mt-4 p-4 bg-light rounded">
          <p className="mb-0">No projects found for this customer.</p>
        </div>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Created</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {listReports.map((report) => (
              <tr key={report.id}>
                <td>{report.name}</td>
                <td>{report.type}</td>
                <td>
                  <UserFriendlyDate timestamp={report.created_at} />
                </td>
                <td>{report.state}</td>
                <td width="120px">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Action
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href={`/report/view/${report.id}`}>
                        View
                      </Dropdown.Item>
                      <Dropdown.Item as="button" onClick={() => handleDeleteClick(report)}>
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <ConfirmationDialog
        show={showDeleteDialog}
        onHide={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title="Delete Project"
        message={`Are you sure you want to delete project "${reportToDelete?.name}"? This action cannot be undone.`}
        confirmLabel="Delete"
        cancelLabel="Cancel"
      />
    </Container>
  );
};

export default CustomerReports;